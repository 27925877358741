import React, { useEffect, useState } from "react";
import { authMerchant, saveCard } from "../services/krti.service";
import Leftbackarrow from "../assets/images/leftbackarrow.svg";
import toast from "react-hot-toast";
import CheckoutFrame from "./CheckoutFrame";
import { Frames } from "frames-react";

const SaveCards = ({ payload, setAddCards, paycart }) => {
  const merchant_secret = sessionStorage.getItem('merchant_secret');
  const client_id = sessionStorage.getItem('client_id');
  const [submitError, setSubmitError] = useState("");
  const [checkedIndex, setCheckedIndex] = useState(false);
  
  console.log('payload::: ', payload);
  const SaveCard = async (checkoutRes) => {
    try {
      console.log("checkoutRes::: ", checkoutRes);
      if (Object.keys(checkoutRes).length > 0) {
        console.log("payload?.user_data?.id::: ", payload?.user_data?.id);
        setSubmitError(null);
        const req = {
          user_id: payload?.user_data?.id,
          token: checkoutRes.token,
          expiry_year: checkoutRes.expiry_year,
          last4: checkoutRes.last4,
          is_save_card: true,
        };
        console.log("req::: ", req);
        const res = await saveCard(req);
        if (res?.data?.statusCode === 200) {
          const data = res?.data?.data;
          if (data?.isSuccess === false) {
            console.log("data?.isSuccess === false)::: ");
            setTimeout(() => {
              console.log("setTimeout::: ");
              Frames.init();
              setSubmitError(null);
            }, 2000);
            return setSubmitError(
              res?.data?.data?.message || res?.message || res?.data?.message
            );
          }
          window.location.href = "/payment/success";
        } else {
          setSubmitError(res?.message || res?.data?.message);
          setTimeout(() => {
            console.log("setTimeout::: ");
            Frames.init();
            setSubmitError(null);
          }, 2000);
        }
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
        setSubmitError(msg || error?.message);
        setTimeout(() => {
          Frames.init();
          setSubmitError(null);
        }, 2000);
      }
      console.log(error.message);
    }
  };

  const handleSaveCard = (event) => {
    const checked = event.target.checked;
    sessionStorage.setItem("saveCard", checked);
    setCheckedIndex(checked);
  };
  const authKrtiMerchant = async () => {
    try {
      const req = {
        amount: payload?.amount,
        mobile: payload?.mobile,
        mobile_code: payload?.mobile_code,
        transaction_type: payload?.transaction_type,
        voucher_id: payload?.voucher_id,
        merchant_secret,
        client_id,
      };

      const res = await authMerchant(payload?.brand_id, req);
      if (res?.data?.statusCode === 200) {
        sessionStorage.setItem("K-token", res?.data?.data?.token);
      } else {
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (payload.transaction_type !== "savecard") {
      document.body.classList.add("loading-indicator-savecard");
      setTimeout(() => {
        document.body.classList.remove("loading-indicator-savecard");
      }, 2000);
    }
    if (payload.transaction_type === "savecard") {
      authKrtiMerchant();
    }
  }, []);

  return (
    <>
      {payload.transaction_type !== "savecard" ? (
        <>
          <div className="plr_24">
            {payload.transaction_type !== "savecard" ? (
              <div className="mb_34">
                <button
                  className="backCirclebtn platonicBlueCircle"
                  onClick={() => setAddCards(false)}
                >
                  <img src={Leftbackarrow} alt="Leftbackarrow" />
                </button>
              </div>
            ) : null}
            {/* <div>
          <h2 className="fontSize22 fontWeight500 blackClr_text mb_8">
            Add card details
          </h2>
        </div> */}

            {/* CHECKOUT FRAME */}
            <div className="celestialCathedralFrame">
              <CheckoutFrame
                SaveCard={SaveCard}
                payload={payload}
                paycart={paycart}
              />

              {payload.transaction_type !== "savecard" ? (
                <div className="inputCheckbox">
                  <input
                    id="useWallet"
                    type="checkbox"
                    onChange={(e) => handleSaveCard(e)}
                  />
                  <label htmlFor="useWallet">Secure this card</label>
                </div>
              ) : null}
            </div>

            <div className="displayFlex gap5px center">
              {submitError && <div className="errorMsg">{submitError}</div>}
            </div>
          </div>
          <div className="pageFooter">
            <div>
              <button
                id="pay-button"
                onClick={(e) => {
                  Frames.submitCard();
                }}
                className="blackMainbtn width100per height50px"
              >
                {payload.transaction_type !== "savecard"
                  ? checkedIndex
                    ? "SAVE & PROCEED"
                    : "PROCEED"
                  : null}
                {payload.transaction_type === "savecard" ? "SAVE CARD" : null}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="mobilePageWrapper pt_60">
          <div className="plr_24">
            {payload.transaction_type !== "savecard" ? (
              <div className="mb_34">
                <button
                  className="backCirclebtn platonicBlueCircle"
                  onClick={() => setAddCards(false)}
                >
                  <img src={Leftbackarrow} alt="Leftbackarrow" />
                </button>
              </div>
            ) : null}
            {/* <div>
          <h2 className="fontSize22 fontWeight500 blackClr_text mb_8">
            Add card details
          </h2>
        </div> */}

            {/* CHECKOUT FRAME */}
            <div className="celestialCathedralFrame">
              <CheckoutFrame
                SaveCard={SaveCard}
                payload={payload}
                paycart={paycart}
              />

              {payload.transaction_type !== "savecard" ? (
                <div className="inputCheckbox">
                  <input
                    id="useWallet"
                    type="checkbox"
                    onChange={(e) => handleSaveCard(e)}
                  />
                  <label htmlFor="useWallet">Secure this card</label>
                </div>
              ) : null}
            </div>

            <div className="displayFlex gap5px center">
              {submitError && <div className="errorMsg">{submitError}</div>}
            </div>
          </div>
          <div className="pageFooter">
            <div>
              <button
                id="pay-button"
                onClick={(e) => {
                  Frames.submitCard();
                }}
                className="blackMainbtn width100per height50px"
              >
                {payload.transaction_type !== "savecard"
                  ? checkedIndex
                    ? "SAVE & PROCEED"
                    : "PROCEED"
                  : null}
                {payload.transaction_type === "savecard" ? "SAVE CARD" : null}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SaveCards;
