import React, { useEffect, useState } from "react";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";

const publicKey = process.env.REACT_APP_CHECKOUT_PUBIC_KEY;
console.log('publicKey::: ', publicKey);

function CheckoutFrame({ SaveCard,payload,paycart }) {

  const [checkoutRes, setCheckoutRes] = useState({});

  useEffect(() => {
    if(payload?.transaction_type === 'savecard' && Object.keys(checkoutRes).length > 0){
      SaveCard(checkoutRes)
    }else if((payload?.transaction_type === 'order' || payload?.transaction_type === 'voucher') && Object.keys(checkoutRes).length > 0){
      paycart(checkoutRes)
    }
  }, [checkoutRes]);

  return (
    <Frames
      config={{
        debug: true,
        publicKey: publicKey,
        // modes: ["cvv_optional"], 
        acceptedPaymentMethods: [
          "Visa",
          "Maestro",
          "Mastercard",
          "American Express",
          "Diners Club",
          "Discover",
          "JCB",
          "Mada",
        ],
        localization: {
          cardNumberPlaceholder: "Card number",
          expiryMonthPlaceholder: "MM",
          expiryYearPlaceholder: "YY",
          cvvPlaceholder: "CVV",
        },
        style: {
          base: {
            fontSize: "17px",
          },
        },
      }}
      ready={() => { }}
      frameActivated={(e) => { }}
      frameFocus={(e) => {
      }}
      frameBlur={(e) => {
        // console.log('frameBlur::: ', e);
      }}
      frameValidationChanged={(e) => {
        console.log('frameValidationChanged::: ', e);
        // setField((prev)=>(
        //   console.log('prev::: ', prev),
        //   {...prev, [e.element] : e.isValid}

        // ) );
      }}
      paymentMethodChanged={(e) => {
        // console.log('EEEE', e);
      }}
      cardValidationChanged={(e) => { }}
      cardSubmitted={(e) => {
        Object.keys(e).length === 0 && document.body.classList.add('loading-indicator');

      }}
      cardTokenized={(e) => {
        console.log(e, "event");
        setCheckoutRes(e)
        document.body.classList.add('loading-indicator');
      }}
      cardTokenizationFailed={(e) => {
        console.log('cardTokenizationFailed', e);

      }}
      cardBinChanged={(e) => { }}
    >
      <CardNumber />
      <div className="date-and-code">
        <ExpiryDate />
        <Cvv />
      </div>

      {/* Or if you want to   use single frames: */}
      {/* <CardFrame /> */}

      {/* <button
        id="pay-button"
        onClick={() => {
          Frames.submitCard();
        }}
      >
        PAY BY KRTI
      </button> */}
    </Frames>
  );
}

export default CheckoutFrame;
