import React, { useEffect, useState } from "react";
import { payNow, savedCardList } from "../services/krti.service";
import VisaImg from "../assets/images/visa_img.png";
import Leftbackarrow from "../assets/images/leftbackarrow.svg";
import CloseIcon from "../assets/images/iconamoon_close.svg";
import AddIcon from "../assets/images/addIcon.svg";
import { formatCurrency } from "./Checkout";
import SaveCards from "./SaveCards";
import { Frames } from "frames-react";

const AddCard = ({
  setStep,
  payload,
  promoDetail,
  handlePrevStep,
  useWallet,
  setTranscation,
  cartValue,
  walletValue,
}) => {
  const [addCards, setAddCards] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [cardList, setCardList] = useState([]);
  const [checkedIndex, setCheckedIndex] = useState(0);
  // const [cardPage, setCardPage] = useState();
  const finalAmount = sessionStorage.getItem("cartTotal");
  const [checkedData, setCheckedData] = useState({});
  const curreny = process.env.REACT_APP_CURRENCY ?? "KWD";
  const dicountAmount = formatCurrency(cartValue - finalAmount);

  const getToken = (checkoutRes) => {
    if (Object.keys(checkoutRes).length !== 0) {
      paycart(checkoutRes);
    }
  };

  let userSessionData = sessionStorage.getItem("K-user");
  let userData = JSON.parse(userSessionData);
  console.log("userData::: ", userData);
  let cashback = sessionStorage.getItem("cashback");
  console.log('cashback::: ', cashback);
  useEffect(() => {
    setSubmitError(null);
    CardList(userData.id);
  }, []);

  const CardList = async (id) => {
    try {
      const res = await savedCardList(id);
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.data;
        if (data?.isSuccess === false) {
          return setSubmitError(
            res?.data?.data?.message || res?.message || res?.data?.message
          );
        }
        if (data.length > 0) {
          setCardList(data);
          setCheckedData(data[0]);
        }
      } else {
        setSubmitError(res?.message || res?.data?.message);
      }
    } catch (err) {
      console.log("err::: ", err);
    }
  };

  const paycart = async (checkoutRes) => {
    let saveCard = sessionStorage.getItem("saveCard");
    console.log("saveCard::: ", saveCard);

    try {
      setSubmitError(null);
      const req = {
        token: checkoutRes.token,
        currency: curreny,
        amount: Number(finalAmount),
        cashback: Number(cashback)||0,
        user_id: userData?.id,
        wallet_id: userData?.wallet_id,
        brand_id: payload?.brand_id,
        promotion:
          promoDetail?.offer_type === "Percentage"
            ? +promoDetail?.discount_value
            : +promoDetail?.offer_value || +promoDetail?.amount || 0,
        promo_id: promoDetail?.id || null,
        card_expires_on: checkoutRes.expires_on,
        is_save_card: saveCard === "true" ? true : false,
      };
      if (payload?.transaction_type === "voucher") {
        req.voucher_id = payload?.voucher_id;
      }
      console.log("req::: ", req);

      const res = await payNow(req);
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.data;
        if (data?.isSuccess === false) {
          setTimeout(() => {
            console.log("setTimeout::: ");
            Frames.init();
            setSubmitError(null);
          }, 2000);
          return setSubmitError(
            res?.data?.data?.message || res?.message || res?.data?.message
          );
        }
        if (payload.transaction_type !== "order") {
          window.location.href = "/payment/success";
        } else {
          setTranscation(data?.data?.id);
          setStep(6);
        }
      } else {
        setSubmitError(res?.message || res?.data?.message);
        setTimeout(() => {
          console.log("setTimeout::: ");
          Frames.init();
          setSubmitError(null);
        }, 2000);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
        setSubmitError(msg || error?.message);
        setTimeout(() => {
          console.log("setTimeout::: ");
          Frames.init();
          setSubmitError(null);
        }, 2000);
      }
      console.log(error.message);
    }
  };

  const HandleProceed = async () => {
    try {
      setSubmitError(null);

      if (Object.keys(checkedData).length === 0 && cardList.length > 0) {
        return setSubmitError("Please select a card");
      }

      if (Object.keys(checkedData).length === 0 && cardList.length === 0) {
        return setSubmitError("Please add new card for payment");
      }

      const req = {
        card_id: checkedData?.card_id,
        src_id: checkedData?.source_id,
        currency: curreny,
        // payment_id: checkedData?.payment_id ?? "pay_vt2edng34orulck2jsjteapkxy",
        amount: Number(finalAmount),
        cashback:
          Number(cashback)||0,
        user_id: userData?.id,
        wallet_id: userData?.wallet_id,
        brand_id: payload?.brand_id,
        promotion:
          promoDetail?.offer_type === "Percentage"
            ? +promoDetail?.discount_value
            : +promoDetail?.offer_value || +promoDetail?.amount || 0,
        promo_id: promoDetail?.id || null,
        is_save_card: false,
      };

      if (payload?.transaction_type === "voucher") {
        req.voucher_id = payload?.voucher_id;
      }

      const res = await payNow(req);
      if (res?.data?.statusCode === 200) {
        const data = res?.data?.data;
        if (data?.isSuccess === false) {
          return setSubmitError(
            res?.data?.data?.message || res?.message || res?.data?.message
          );
        }
        if (payload?.transaction_type !== "order") {
          window.location.href = "/payment/success";
        } else {
          setTranscation(data?.id);
          // handleNextStep()
          setStep(6);
        }
      } else {
        setSubmitError(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
        setSubmitError(msg || error?.message);
      }
      console.log(error.message);
    }
  };

  const handleRadioChange = (index, data) => {
    setCheckedIndex(index);
    setCheckedData(data);
  };

  const CardPage = () => {
    console.log("CardPage::: ");
    setSubmitError(null);
    setAddCards(true);
    // document.body.classList.add('loading-indicator');
  };

  return (
    <div className="mobilePageWrapper pt_34">
      {!addCards && (
        <>
          <div>
            <div className="plr_24">
              <div className="mb_34 textAlignRight">
                <button
                  className="backCirclebtn platonicBlueCircle"
                  onClick={handlePrevStep}
                >
                  <img src={CloseIcon} alt="CloseIcon" />
                </button>
              </div>
              {/* <div className={`plr_24 ${cardList?.length === 0 ? 'bankCardHeight' : ''}`}> */}
              <div className={cardList?.length <= 3 ? "" : "bankCardHeight"}>
                {cardList?.length > 0 ? (
                  <>
                    {cardList?.map((data, I) => (
                      <label className="bankCardFrame mb_12 displayFlex alignItem_center justifyContent_spacebetween">
                        <div className="displayFlex alignItem_center gap8">
                          <div className="bankImgFrame">
                            <img src={VisaImg} alt="VisaImg" />
                          </div>
                          <div>
                            <h5 className="fontSize16 blackClr_text fontWeight500">
                              {data.issuer}
                            </h5>
                            <p className="fontSize14 SteelWoolClr_text fontWeight400">
                              {data?.card_number}
                            </p>
                          </div>
                        </div>
                        <div className="prefer_method ">
                          <input
                            type="radio"
                            id={`bankOne ${I}`}
                            name="bankCard"
                            checked={I === checkedIndex}
                            onChange={() => handleRadioChange(I, data)}
                          />
                          <label htmlFor={`bankOne ${I}`}></label>
                        </div>
                      </label>
                    ))}
                  </>
                ) : (
                  <p style={{ "text-align": "center" }}>
                    No Saved cards available
                  </p>
                )}
              </div>
            </div>
            <div className="plr_24">
              <div className="bankCardFrame mb_70 displayFlex alignItem_center justifyContent_spacebetween cursorPointer">
                <div
                  className="displayFlex alignItem_center gap8"
                  onClick={() => CardPage()}
                >
                  <div className="bankImgFrame">
                    <img src={AddIcon} alt="AddIcon" />
                  </div>
                  <div>
                    <h5 className="fontSize16 blackClr_text fontWeight500">
                      Add Card
                    </h5>
                    <p className="fontSize14 SteelWoolClr_text fontWeight400">
                      Save and Pay via Cards
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="plr_24">
              <h2 className="fontSize22 fontWeight500 blackClr_text mb_12">
                {" "}
                {payload?.transaction_type === "order"
                  ? "Order details"
                  : "Voucher details"}{" "}
              </h2>

              <div className="table_detail">
                <table>
                  {
                    <tr>
                      <th> Cart Amount:</th>
                      <td> {formatCurrency(cartValue)} </td>
                    </tr>
                  }
                  {promoDetail?.discount_value || promoDetail?.amount ? (
                    <tr>
                      <th> Promotional discount:</th>
                      <td>
                        -{" "}
                        {formatCurrency(
                          promoDetail?.discount_value || promoDetail?.amount
                        )}{" "}
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {
                    <tr>
                      {useWallet && (
                        <th>
                          <label htmlFor="useWallet">Wallet Cash </label>
                        </th>
                      )}
                      <td>
                        {" "}
                        {useWallet &&
                          "- " +
                            formatCurrency(
                              walletValue >
                                cartValue -
                                  (parseFloat(promoDetail?.discount_value) ||
                                    parseFloat(promoDetail?.amount) ||
                                    0)
                                ? cartValue -
                                    (parseFloat(promoDetail?.discount_value) ||
                                      parseFloat(promoDetail?.amount) ||
                                      0)
                                : walletValue
                            )}{" "}
                      </td>
                    </tr>
                  }
                  <tfoot>
                    <tr>
                      <th>
                        <label htmlFor="useWallet">Total discount</label>
                      </th>
                      <td>- {dicountAmount} </td>
                    </tr>
                    <tr>
                      <th className="fontweight500">Total amount</th>
                      <td>
                        <span className="fontweight500">
                          {formatCurrency(finalAmount)}
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="displayFlex gap5px">
                {submitError && <div className="errorMsg">{submitError}</div>}
              </div>
            </div>
          </div>
          <div className="pageFooter">
            <div>
              <button
                onClick={HandleProceed}
                className="blackMainbtn width100per height50px"
              >
                Pay now
              </button>
            </div>
          </div>
        </>
      )}

      {addCards && (
        <SaveCards
          payload={payload}
          setAddCards={setAddCards}
          paycart={paycart}
          getToken={getToken}
        />
      )}
    </div>
  );
};

export default AddCard;
