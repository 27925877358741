import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error to an error reporting service
        console.error('Error boundary caught an error:', error, errorInfo);
    }

    handleRefresh = () => {
        // Refresh the page
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <h2>Something went wrong.</h2>
                    <p>Please refresh the page or try again later.</p>
                    <button onClick={this.handleRefresh}>Refresh</button>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
