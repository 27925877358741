import { KrtiPayService } from "./api";

const token = sessionStorage.getItem("K-token");
console.log("token::: ", token);

// const { KRTI_MOBILE_API, KRTI_WEB_API, merchant_secret, brand_id, client_id } = env;

const header = {
  merchant_secret: sessionStorage.getItem("merchant_secret"),
  client_id: sessionStorage.getItem("client_id"),
  Authorization: 'Bearer ' + token
};

const back = () =>{
        console.log("KRTI-SERVICE");
        setTimeout(() => {
            window.parent.postMessage("back", "*");
        }, 2000);
}

export const authMerchant = async (id, req) => {
  try {
    const header = {
      merchant_secret: sessionStorage.getItem("merchant_secret"),
      client_id: sessionStorage.getItem("client_id"),
    };
    const reqHeader = { ...req, header };

    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_WEB_API}/merchant-payments/validateClient/${id}`,
      {},
      { headers: reqHeader }
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
       back()
    }
    return error?.response?.data;
  }
};

export const registerCustomer = async (req) => {
  try {
    let body = {
      ...req,
      otpSignature: "s",
      session_id: "43eb3f62-270e-4f93-9b85-5e6c0d4c824a",
    };

    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_MOBILE_API}/user/registerUser`,
      body,
      { headers: header }
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

export const verifyOtp = async (req) => {
  try {
    let body = {
      ...req,
      verifyType: "Login or Register",
    };

    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_MOBILE_API}/user/verifyOtp`,
      body,
      { headers: header }
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

export const resentOtpService = async (mobile) => {
  try {
    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_MOBILE_API}/user/resendOtp/${mobile}/signature`,
      {},
      { headers: header }
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

export const payNow = async (req) => {
  try {
    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_WEB_API}/payment/checkoutPayment`,
      req,
      { headers: header }
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

export const saveCard = async (req) => {
  try {
    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_WEB_API}/payment/save-card`,
      req,
      { headers: header }
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

export const savedCardList = async (id) => {
  try {
    return await KrtiPayService.get(
      `${process.env.REACT_APP_KRTI_WEB_API}/payment/card-list/${id}`,
      { headers: header }
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

export const payByWallet = async (req) => {
  try {
    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_WEB_API}/payment/walletUpdate`,
      req
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

// cupon service :

export const verifyPromo = async (code, amount,userId,brand_id) => {
  try {
    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_WEB_API}/campaign-mangement/verifyCoupon/${code}/${amount}/${userId}/${brand_id}`,
      {}
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

export const redeemCupon = async (req) => {
  try {
    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_WEB_API}/campaign-mangement/redeem-coupon`,
      req
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};

export const GetOfferList = async (req) => {
  try {
    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_WEB_API}/merchant-payments/get-bestbrand-offers`,
      req
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
        console.log(
          "error?.response?.data.statusCode::: ",
          error?.response?.data.statusCode
        );
       back()
    }
    return error?.response?.data;
  }
};

export const UserCashback = async (req) => {
  try {
    return await KrtiPayService.post(
      `${process.env.REACT_APP_KRTI_WEB_API}/campaign-mangement/get-user-cashback`,
      req
    );
  } catch (error) {
    console.log(error);
    if (error?.response?.data.statusCode === 401) {
      back()
    }
    return error?.response?.data;
  }
};
