import React, { useState, useEffect } from 'react';

const OTPTimer = ({ expiryTimeInSeconds, onExpire, startTimer }) => {
    const [timeLeft, setTimeLeft] = useState(expiryTimeInSeconds);

    useEffect(() => {
        if (startTimer) {
            setTimeLeft(expiryTimeInSeconds);
        }
    }, [expiryTimeInSeconds, startTimer]);

    useEffect(() => {
        if (timeLeft === 0) {
            onExpire();
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft, onExpire]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div>
            <p className='fontSize16 fontWeight400 blackStallionText textalign_right'>Resend OTP in :<span >  {formatTime(timeLeft)}</span></p>

        </div>
    );
};

export default OTPTimer;
