import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  GetOfferList,
  authMerchant,
  payByWallet,
  verifyPromo,
} from "../services/krti.service";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import logo from "../assets/images/krti-logo.svg";
import checkoutbanner from "../assets/images/checkout_banner.png";
import OfferIcon from "../assets/images/offer_icon.svg";
import VoucherIcon from "../assets/images/voucher_icon.svg";
// import Leftbackarrow from "../assets/images/leftbackarrow.svg";
import CloseIcon from "../assets/images/iconamoon_close.svg";

import Accordion from "react-bootstrap/Accordion";
import CircleDollerIcon from "../assets/images/circleDoller_icon.svg";
import OfferHeadIcon from "../assets/images/offerHeadImg.svg";

import OfferActiveIcon from "../assets/images/offerActive.svg";
import VoucherActiveIcon from "../assets/images/voucherActive.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const currency = process.env.REACT_APP_CURRENCY;
export function formatCurrency(value) {
  try {
    // Ensure the input is treated as a number
    const number = Number(value);
    if (isNaN(number)) {
      return `KWD ${value}`;
    }
    if (!currency) {
      return `KWD ${value}`;
    }

    // Determine the appropriate number of fraction digits
    const hasDecimalPart = number % 1 !== 0;
    const minimumFractionDigits = hasDecimalPart ? 2 : 0;
    const maximumFractionDigits = hasDecimalPart ? 2 : 0;

    // Format the number using toLocaleString
    let formattedNumber = number.toLocaleString("en-KW", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    });

    // Replace the currency code with the desired symbol if the currency is KWD
    if (currency === "KWD") {
      formattedNumber = formattedNumber.replace("KD", "KWD");
    }
    // 'د.ك'
    return formattedNumber;
  } catch (error) {
    // Handle errors gracefully by returning a default format
    console.error(error.message);
    return `KWD ${value}`;
  }
}

const Cart = ({
  walletValue,
  cartValue,
  setWalletValue,
  handleNextStep,
  payload,
  promoDetail,
  setPromodetail,
  handlePrevStep,
  widgetCompleted,
  setWidgetCompleted,
  useWallet,
  setUseWallet,
  // new
  offerList,
  // voucherList,
  handleSubmitPromo,
  handleApplyPromoCode,
  removePromo,
  isValidPromo,
  promoCode,
  setPromoCode,
  promoText,
  setIsOfferList,
  checkedIndex,
  handleCheckboxChange,
  offerArr,
  voucherArr
}) => {
  return (
    <>
      <div className="mobilePageWrapper pt_34">
        <div className="plr_24">
          <div className="mb_34 textAlignRight">
              <button
                className="backCirclebtn platonicBlueCircle"
                onClick={() => setIsOfferList(false)}
              >
              <img src={CloseIcon} alt="CloseIcon" />
              </button>
            </div>

          {payload?.transaction_type === "order" ? (
            <>
              <div className="mb_24">
                <h5 className="fontSize22 fontWeight600 blackClr_text">
                  Offers & Vouchers
                </h5>
                {/* <div className="promo-code-container">
                                    <input
                                        type="text"
                                        value={promoCode}
                                        onChange={(e) => setPromoCode(e.target.value)}
                                        placeholder="Enter promo code"
                                        className="promo-code-input"
                                    />
                                    {!promoText && <button onClick={() => handleApplyPromoCode(promoCode)} className="MainButton cyanSky_clr">Apply</button>}
                                    {promoText && <button onClick={removePromo} className="MainButton errorText_clr">Remove</button>}
                                </div> */}

                {/* <div className="Groupinput">
                                    <input type="text"
                                        value={promoCode}
                                        onChange={(e) => setPromoCode(e.target.value)}
                                        name="new_password"
                                        placeholder="Enter promo code"
                                    />

                                    <div className="inputgroup_append">
                                        <span className="Groupinput_text">

                                            {!promoText && <button onClick={() => handleApplyPromoCode(promoCode)} className="helloSummerClrText fontSize16 fontWeight400 textDecoration_underline emptyBtn">Apply</button>}
                                            {promoText && <button onClick={removePromo} className="bloodDonorClrText fontSize16 fontWeight400 textDecoration_underline emptyBtn">Remove</button>}



                                        </span>
                                    </div>
                                </div>
                                {!isValidPromo && <div className='errorMsg'>{promoText}</div>}
                                {isValidPromo && promoText && <div className='successMsg '>{promoText}</div>} */}
              </div>
              <div className="checkoutTab">
                <Tabs
                  defaultActiveKey="offerTab"
                  transition={false}
                  id="noanim-tab-example"
                >
                  <Tab
                    eventKey="offerTab"
                    title={
                      <>
                            <img className="normalImg" src={OfferHeadIcon} alt="OfferHeadIcon" />
                            <img className="ActiveImg" src={OfferActiveIcon} alt="OfferActiveIcon" />
                             
                        <span>Offers</span>
                      </>
                    }
                  >
                    {offerList?.length > 0 && offerArr.length > 0 ?  (
                     <div>
                     <Accordion defaultActiveKey="0">
                 { offerList.map((data,i) => (
                   data.code === undefined ? (
                       <Accordion.Item eventKey={`${i}`} key={i}>
                         <Accordion.Header>
                           <div>
                             <h2 className="fontSize24 fontWeight500 blackStallionClrText">
                             {formatCurrency(data?.discount_value)} Off
                             </h2>
                           </div>
                           <div className="ml_auto mr_16">
                             <label className="whiteBtn">
                               {data?.campaign_promo}
                             </label>
                           </div>
                         </Accordion.Header>
                         <Accordion.Body>
                           <p className="fontSize16 fontWeight400 blackStallionClrText mb_16">
                             {data?.details}
                           </p>
   
                           <label className="custom_switch textAlignRight">
                             <input className="toggle_checkbox" type="radio" checked = {i === checkedIndex}
                              onChange={(e) => handleCheckboxChange(e,data?.campaign_promo,i)}
                              />
                             <div className="toggle_switch"></div>
                             {/* <span className="toggle-label">sasasasasasa</span> */}
                           </label>
                         </Accordion.Body>
                       </Accordion.Item>
                   ) : null
                   ))}
                     </Accordion>
                   </div>
                    ) : (
                      <div className="nodataFound">
                          <p className="fontSize16 blackClr_text textAlignCenter fontWeight400">No Offer found</p>

                        </div>
                    )}
                  </Tab>
                  <Tab
                    eventKey="voucherTab"
                    title={
                      <>
                            <img className="normalImg" src={CircleDollerIcon} alt="CircleDollerIcon" />
                            <img className="ActiveImg" src={VoucherActiveIcon} alt="VoucherActiveIcon" />

                            
                        <span>Vouchers</span>
                      </>
                    }
                  >
                    {offerList?.length > 0 && voucherArr.length > 0 ? (
                     <div className="mb_50">
                     <Accordion>
                 {offerList.map((data,i) => (
                   data.campaign_promo === undefined ? (
                       <Accordion.Item eventKey={`${i}`} key={i}>
                         <Accordion.Header>
                           <div>
                             <h2 className="fontSize24 fontWeight600 blackStallionClrText">
                               {formatCurrency(data?.amount)} Off
                             </h2>
                           </div>
                           <div className="ml_auto mr_16">
                             <label className="whiteBtn">{data?.code}</label>
                           </div>
                         </Accordion.Header>
                         <Accordion.Body>
                           <p className="fontSize16 fontWeight400 blackStallionClrText mb_16">{data?.details}</p>
   
                                     <label className="custom_switch textAlignRight">
                             <input className="toggle_checkbox" 
                             type="radio" 
                              checked={checkedIndex === i}
                              id = {i}
                              onChange={(e) => handleCheckboxChange(e,data?.code,i)}
                             />
                             <div className="toggle_switch"></div>
                             {/* <span className="toggle-label">sasasasasasa</span> */}
                           </label>
                         </Accordion.Body>
                       </Accordion.Item>
                   ) : null
                       ))}
                     </Accordion>
                   </div>
                    ) : (
                        <div className="nodataFound">
                          <p className="fontSize16 blackClr_text textAlignCenter fontWeight400">No voucher found</p>

                      </div>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

      </div>
    </>
  );
};

export default Cart;
