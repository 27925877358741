import { useRoutes } from "react-router-dom";
import UnauthorizedDisplay from './components/UnauthorizedDisplay';
import RedirectComp from "./components/Redirect";
import App from "./App";

const RouteComponent = () => {

    const routes = useRoutes([
        { path: "/", element: <App /> },
        { path: "/payment/success", element: <RedirectComp /> },
        { path: "*", element: <UnauthorizedDisplay /> },
    ]);
    return <>{routes}</>;
};
// gh


export default RouteComponent;