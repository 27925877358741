import axios from 'axios';
import Cookies from 'js-cookie';
// import { SessionStorage } from './SessionStorage';


let requestCounter = 0



export const KrtiPayService = axios.create({
    // baseURL: url,
    headers: {
        'Content-Type': "application/json",

    }
})




KrtiPayService.interceptors.request.use(function (config) {
    requestCounter++
    document.body.classList.add('loading-indicator');
    const token = Cookies.get('K-token') || sessionStorage.getItem('K-token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, function (error) {
    return Promise.reject(error);
});

KrtiPayService.interceptors.response.use(function (response) {
    requestCounter--;

    if (requestCounter === 0)
        document.body.classList.remove('loading-indicator');

    return response;
}, function (error) {
    requestCounter--;
    if (requestCounter === 0)
        document.body.classList.remove('loading-indicator');

    return Promise.reject(error);
});


