import React, { useEffect, useState } from 'react';
import { verifyOtp, resentOtpService } from '../services/krti.service';
import logo from "../assets/images/krti-logo.svg";
import otpBanner from "../assets/images/otp_banner.png";
import OtpInput from 'react-otp-input';
import OTPTimer from './otp-timer';
import Leftbackarrow from "../assets/images/leftbackarrow.svg";

const OtpVerification = ({ otp, setOtp, handleNextStep, setIsNewUser, handlePrevStep, phone }) => {
    const [error, setError] = useState('');
    const [user, setUser] = useState({});
    const [isTimer, setTimer] = useState(true);

    const phoneCode = sessionStorage.getItem('phoneCode') || "";
    const phoneNumber = sessionStorage.getItem('phoneNumber') || "";
    const phoneLastdigit = phoneNumber?.slice(-4)


    useEffect(() => {
        setUser(JSON.parse(sessionStorage?.getItem('K-user')));
        return () => {
            setOtp('')
        }
    }, [])

    const verifyMobileOtp = async (otp) => {
        try {
            const req = { mobile: user.mobile, otp: +otp }
            const res = await verifyOtp(req);
            if (res?.data?.statusCode === 200) {

                let user = JSON.parse(sessionStorage.getItem('K-user'));
                let walletamount = res.data?.data
                sessionStorage.setItem('K-user', JSON.stringify({ ...walletamount, ...user }))
                handleNextStep()
            } else {
                setError(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                console.error(msg)
                setError(error?.message);
            }

        }

    }

    const resentOtp = async () => {
        try {
            setTimer(true)
            const phone = sessionStorage.getItem('phoneCode') + sessionStorage.getItem('phoneNumber')
            const res = await resentOtpService(phone);
            if (res?.data?.statusCode === 200) {

                setError(null);

            } else {
                setError(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                console.error(msg)
                setError(error?.message);
            }
            console.log(error?.message);
        }

    }

    const handleSubmit = () => {
        if (otp.length !== 4) {
            setError('Please enter OTP');
        } else {
            setError('');
            verifyMobileOtp(otp)
        }
    };

    const inputStyle = {
        width: '3rem',
        height: '3rem',
        margin: '0 0.5rem',
        fontSize: '1.5rem',
        borderRadius: '4px',
        border: 'none',
        outline: 'none',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', // optional: adds a slight shadow for visibility
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const onExpire = () => {
        setTimer(false)
    }

    return (
        <>
            <div className='loginScreenWrapper'>
                <div className='mb_34'>

                    <button onClick={handlePrevStep} className='blackStallionCircle backCirclebtn'>
                        <img className='ml_min2' src={Leftbackarrow} alt='Leftbackarrow' />
                    </button>
                    
                </div>
                <div>

                    {/* <img src={logo} alt="Logo" className='pbottom20' /> <br />
                    <img src={otpBanner} alt="Banner" /> */}

                    <h2 className='fontSize40 blackStallionText fontWeight400 mb_40' style={{ lineHeight: '50px' }}>Enter Verification Code</h2>

                    <p className='fontSize16 fontWeight500 blackStallionText textAlignCenter mb_16'>Verification code has been sent <br /> to +{phoneCode} XXXXXX{phoneLastdigit}</p>
               
                    <div className='mb_70 OtpInput'>
                        <OtpInput 
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={inputStyle}
                        containerStyle={containerStyle}
                        shouldAutoFocus
                    />
                        {error && <p className="fontSize14 fontWeight400  errorText textAlignCenter mt_12">{error}</p>}
                  

                    </div>
                   
                    {/* <button onClick={resentOtp}>Resend otp</button> */}
                    {/* <button onClick={handlePrevStep}>Back</button> */}
                    <div className='mb_16'>
                        <button onClick={handleSubmit} className='loginbtn height50px'>Verify</button>
                    </div>

                    {isTimer &&
                        <p className=' fs14 textAlignCenter'>
                            <OTPTimer expiryTimeInSeconds={70} onExpire={onExpire} startTimer={isTimer} />
                        </p>}

                    {
                        !isTimer &&
                        <p className=' fontSize16 fontWeight400  errorText textAlignCenter mt_12'>Didn’t you receive the OTP? <span onClick={resentOtp} >Resend OTP</span></p>

                    }
                </div>
            </div>
        </>
    );
};

export default OtpVerification;
